import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark: post } = data // data.markdownRemark holds our post data
  return (
    <Layout data={data}>
      <div className="blog-post-container">
        <Helmet title={`Learn Beatflyer - ${post.frontmatter.title}`} />
        <a href="/" className="back">
          &lt; All tutorials
        </a>
        <div className="blog-post">
          <h1>{post.frontmatter.title}</h1>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          {post.frontmatter.videoURL.length > 0 && (
            <iframe
              title="video"
              width="700"
              height="394"
              src={post.frontmatter.videoURL}
              frameBorder="0"
              allowFullScreen={true}
            ></iframe>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        videoURL
      }
    }
  }
`
